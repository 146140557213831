import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";

const TestyAbPage = ({ location: { pathname } }) => {
    return (
        <Layout
            seo={{
                title: "Testy A/B Google Optimize",
                href: pathname,
                lang: "pl",
            }}
        >
            <span className="anchor" id="home"></span>
            <div className="subpage-header subpage-header--single-offer">
                <h1 className="subpage-header__title">
                    Testy A/B <br /> <span>Google Optimize</span>
                </h1>
            </div>

            <div className="single-offer-menu-container">
                <div className="row">
                    <div className="col-lg-3">
                        <Link to="/uslugi/#analityka">
                            <div className="back-wrapper">
                                <span className="back-wrapper__arrow"></span>
                                <p className="back-wrapper__title">Usługi</p>
                            </div>
                        </Link>
                        <p className="nav-title">Specjalizacje:</p>
                        <ul>
                            <li>
                                <Link to="/konfiguracja-i-wdrożenie-google-analytics-4/">
                                    Konfiguracja i wdrożenie Google Analytics 4
                                </Link>
                            </li>
                            <li>
                                <Link to="/wdrozenie-analityki/">
                                    Wdrożenie analityki
                                </Link>
                            </li>
                            <li className="current">
                                <a href="#home">Testy A/B</a>
                            </li>
                            <li>
                                <Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
                                    Analiza strony internetowej przed startem
                                    kampanii PPC
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="single-offer-sections single-offer-sections--category-4">
                <section className="single-offer-section-text-left single-offer-section-text-left--cat-4 single-offer-section-text-left--testy-ab">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row align-items-center">
                                <div className="col-lg-6 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Nie zgaduj - sprawdź!
                                    </h2>
                                    <p className="text-col__desc">
                                        Zastanawiasz się, czy wprowadzenie
                                        konkretnej zmiany na stronie pozytywnie
                                        wpłynie na poprawę jej jakości i
                                        funkcjonalności? Czy inne zdjęcia
                                        wpłynęłyby na zainteresowanie Twoją
                                        ofertą lub czy inny kolor buttonów
                                        wpłynie na ich klikalność? Nie zgaduj -
                                        sprawdź. Dzięki narzędziu{" "}
                                        <span>Google Optimize</span> możemy
                                        przetestować różne warianty witryny lub
                                        jej poszczególnych elementów, a
                                        następnie sprawdzić, czy zmiana ta
                                        wpłynęła na poprawę interesujących nas
                                        parametrów, takich jak klikalność
                                        buttonu, czas na stronie, wypełnienie
                                        formularza itp.
                                    </p>
                                </div>
                                <div className="col-lg-6 img-col">
                                    <img
                                        className="img-fluid img-col__image"
                                        src={require("../assets/images/testy-ab-img-1.jpg")}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-text-left single-offer-section-text-left--testy-ab-second  zero-margin">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row align-items-center">
                                <div className="col-lg-11 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Różnorodność eksperymentów
                                    </h2>
                                    <p className="text-col__desc mb-40">
                                        Za pomocą <span>Google Optimize</span>{" "}
                                        możliwa jest zmiana wszystkich elementów
                                        witryny. Testować możemy drobne zmiany,
                                        jak również dwie zupełnie różne wersje
                                        strony w jednej domenie. Dzięki
                                        eksperymentom możemy dowiedzieć się:
                                    </p>
                                    <ul className="big-bullets mb-70">
                                        <li>
                                            czy umieszczenie formularza w innym
                                            miejscu wpłynie na wzrost konwersji
                                            z jego wypełnienia, tak samo jak
                                            ograniczenie ilości wierszy
                                        </li>
                                        <li>
                                            czy zmiana koloru buttonów wpłynie
                                            na ich klikalność, takich jak np.
                                            button “Dodaj do koszyka” lub
                                            “Sprawdź” nawołujący do danej akcji
                                        </li>
                                        <li>
                                            czy zmiana nazw zakładek wpłynie na
                                            ich klikalność
                                        </li>
                                        <li>
                                            czy zmiana informacji o promocji
                                            zwiększy zainteresowanie produktami
                                            objętymi promocją
                                        </li>
                                        <li>
                                            czy zmiana call to action zachęci
                                            użytkownika w większym stopniu do
                                            wypełnienia formularza kontaktowego
                                        </li>
                                        <li>
                                            czy wykorzystanie innych zdjęć (np.
                                            z osobami, zamiast grafik) wpłynie
                                            na lepszy odbiór oferty.
                                        </li>
                                    </ul>
                                    <img
                                        src={require("../assets/images/test-ab-graph.png")}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-text-left single-offer-section-text-left--testy-ab-third zero-margin">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row align-items-center">
                                <div className="col-lg-11 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Co jest celem eksperymentu?
                                    </h2>
                                    <p className="text-col__desc mb-40">
                                        Aby eksperyment się powiódł, musimy
                                        wyznaczyć jego cel. <br /> Celem
                                        eksperymentu mogą być przykładowo:
                                    </p>
                                    <ul className="big-bullets mb-70">
                                        <li>pobranie PDF</li>
                                        <li>wypełnienie formularza</li>
                                        <li>odwiedzenie zakładki “kontakt”</li>
                                        <li>dodanie produktu do koszyka</li>
                                        <li>kontakt telefoniczny</li>
                                        <li>sprzedaż</li>
                                    </ul>
                                    <p className="text-col__desc mb-70">
                                        W zależności od wyznaczonych celów muszą
                                        one zostać wcześniej skonfigurowane za
                                        pomocą konta Google Analytics (i/lub
                                        Google Tag Managera), które musi zostać
                                        zsynchronizowane.
                                    </p>
                                    <img
                                        src={require("../assets/images/testy-ab-graph-2.jpg")}
                                        alt=""
                                        className="img-fluid mb-40"
                                    />
                                    <p className="text-col__desc font-small">
                                        Przykład wyników testu Google Optimize
                                        dla strony inwestycji deweloperskiej.
                                        Test bazujący na podmianie buttona
                                        „Dowiedz się więcej” na „Wyślij
                                        formularz” w sliderze strony dla
                                        realizacji celu – wysłanie formularza
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-timeline single-offer-section-timeline--testy-ab">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 offset-lg-4">
                            <div className="header-container">
                                <div className="custom-row">
                                    <div className="left-col">
                                        <img
                                            src={require("../assets/images/ico-arrow-timeline.svg")}
                                            alt=""
                                            className="header-container__arrow"
                                        />
                                    </div>
                                    <div className="right-col">
                                        <h3 className="header-container__title">
                                            Sprawdź, jak wygląda ścieżka testów
                                            A/B:
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="timeline-container">
                                <span className="timeline-container__line"></span>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                1
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Cel
                                            </p>
                                            <p className="single-item__desc">
                                                Ustalamy cel, jaki ma ulec
                                                poprawie dzięki wprowadzonej
                                                zmianie
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                2
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Ruch na stronie
                                            </p>
                                            <p className="single-item__desc">
                                                na czas trwania testów musimy
                                                dostarczyć na stronę odpowiednio
                                                duży ruch - aby testy przyniosły
                                                wiarygodne efekty należy je
                                                wykonać na reprezentatywnej
                                                grupie użytkowników. Im mniejszy
                                                ruch na stronie, tym dłużej
                                                zajmie zbieranie rzetelnych
                                                danych
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                3
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Przebieg testu
                                            </p>
                                            <p className="single-item__desc">
                                                Wyznaczony procent użytkowników
                                                kieruje na wariant A witryny,
                                                pozostały na wariant B
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                4
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Wstrzymanie zmian na stronie
                                            </p>
                                            <p className="single-item__desc">
                                                Eksperymenty powinny dotyczyć
                                                jednego elementu tak, aby inna
                                                zmiana na stronie nie zaburzyła
                                                statystyk obecnie trwającego
                                                testu. Na czas eksperymentu
                                                wstrzymujemy się więc z innymi
                                                testami i zmianami
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                5
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Analiza
                                            </p>
                                            <p className="single-item__desc">
                                                po zakończeniu eksperymentu
                                                analizujemy efekty i podejmujemy
                                                decyzję o wprowadzeniu zmian na
                                                stałe
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-item">
                                    <div className="custom-row">
                                        <div className="left-col">
                                            <p className="single-item__number">
                                                6
                                            </p>
                                        </div>
                                        <div className="right-col">
                                            <p className="single-item__title">
                                                Dalsze testy
                                            </p>
                                            <p className="single-item__desc">
                                                Testujemy dalej! Optymalizacja
                                                nie kończy się nigdy :)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-publications single-offer-section-publications--testy-ab">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-4 pub-col">
                            <div className="single-book">
                                <div className="single-book__img-wrapper">
                                    <img
                                        className="img-fluid single-book__image"
                                        src={require("../assets/img/book-nie-zgaduj.jpg")}
                                        alt=""
                                    />
                                </div>
                                <div className="text-container">
                                    <p className="text-container__magazine-name">
                                        Magazyn INMAG. Numer 2(4)/2020 (maj)
                                    </p>
                                    <p className="text-container__title">
                                        Nie zgaduj. Testy A/B strony inwestycji
                                        bez ingerencji programisty
                                    </p>
                                    <p className="text-container__desc">
                                        “Na skuteczność kampanii marketingowych
                                        inwestycji deweloperskich ma wpływ wiele
                                        czynników. Z uwagi na mierzalność i
                                        potencjał dotarcia do wyselekcjonowanej
                                        grupy docelowej, działania te coraz
                                        częściej są opierane przez deweloperów
                                        na prowadzeniu płatnej kampanii Google
                                        Ads. Mając na uwadze bardzo duże
                                        znaczenie tego kanału reklamowego dla
                                        sprzedaży inwestycji obecnie, ważna jest
                                        jego poprawna konfiguracja oraz
                                        późniejsza analiza otrzymanych danych.”
                                        <br /> <br />
                                        <strong className="text-container__author">
                                            Patrycja Idzińska, CEO Agencji WHEN
                                        </strong>
                                    </p>
                                    <div className="text-wrapper--inner-action text-container__button">
                                        <a
                                            href="https://www.nieruchomosci-online.pl/publikacje/inmag.html#02-2020"
                                            className="btn btn-line"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            pobierz publikację
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-contact">
                    <div className="form-wrapper">
                        <h2 className="single-offer-section-contact__title mb-40">
                            Skontaktuj się z nami! <br />
                            Wypełnij <span>formularz</span>
                        </h2>
                        <ContactFormDark locales={locales["pl"]} />
                    </div>
                    <img
                        className="img-fluid single-offer-section-contact__hand"
                        src={require("../assets/images/home-contact-hand-right.png")}
                        alt=""
                    />
                </section>
            </div>
        </Layout>
    );
};
export default TestyAbPage;
